function Performances () {
  const selectYear = $('#select_year')
  const warningMsg = $('.warning-messg')
  const warningTxt = 'Please, chose a year'
  const warningCls = 'warning-info'

  $(selectYear).change(function (e) {
    if (selectYear.val() !== '') {
      selectYear.removeClass(warningCls)
      warningMsg.hide()
    };
  })

  $('#submit_filters').click(function (e) {
    e.preventDefault()

    if (selectYear.val() === '') {
      selectYear.addClass(warningCls)
      warningMsg.text(warningTxt)
    } else {
      selectYear.removeClass(warningCls)
      warningMsg.hide()
      $('#filter_form').submit()
    };
  })

  $('.filter').change(
    function () {
      $('#filter').submit()
    }
  )
}

export default Performances
